@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Inter", !important;
  font-size: 12px;
  /* font-family: "inter", 'poppins', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Inter", source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
