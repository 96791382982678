.driver-wrapper {
    background-image: url('../../assets/imgs/female-driver.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: inherit;
}

.platformWrapper {
    background-image: url('../../assets/imgs/artistic-bg.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: inherit;
}