.curved-center-div {
    width: 100%;         /* Width of the main div */
    background-image: url('../../../assets/imgs/footer-curved-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
    height: max-content;
    object-fit: fill;
  }
  
  /* .curved-center-div::before {
    content: "";
    position: absolute;
    top: -20px;              
    left: 0;
    right: 0;
    height: 40px;            
    background-color: #4CAF50;
    border-top-left-radius: 150px;
    border-top-right-radius: 150px;
  }
   */